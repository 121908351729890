'use client'

import { Spacer, Text } from '@vinted/web-ui'

import AppStoreButtons from 'components/AppStoreButtons'
import { useIsDarkMode } from '@marketplace-web/shared/dark-mode'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { CDN_IMAGES_URL } from 'constants/index'

const AppPromo = () => {
  const translate = useTranslate()
  const isDarkMode = useIsDarkMode()
  const darkModeSuffix = isDarkMode ? '_dark' : ''

  return (
    <main className="landing__section u-padding-vertical-none">
      <div className="landing__section-content landing__section-content--wide">
        <div className="app__row">
          <div className="app__cell">
            <div className="landing__section-entry">
              <Text as="h1">
                <span className="c-text--responsive-small-heading">
                  {translate('app_promo.page_title')}
                </span>
              </Text>
              <Spacer />
              <Text as="p">
                <span className="c-text--responsive-small-body">{translate('app_promo.body')}</span>
              </Text>
              <Spacer size={Spacer.Size.X2Large} />
              <AppStoreButtons />
            </div>
          </div>
          <div className="app__cell">
            <img
              className="app__photo"
              src={`${CDN_IMAGES_URL}/app/new/_rebrand/fr/apps${darkModeSuffix}.png`}
              alt=""
            />
          </div>
        </div>
      </div>
      <Spacer size={Spacer.Size.X4Large} />
    </main>
  )
}

export default AppPromo
